
.slideInDown {
  -webkit-animation: slide-down 0.5s forwards;
  animation: slide-down 0.5s forwards;
}
.active-tenure {
  background: rgba(39, 79, 237, 0.04);
  @apply border-blue-500;
}
.tenure-not-active {
  @apply bg-marketplace-lightgray;
  @apply border-marketplace-gray;
}
.loan-details {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply text-marketplace-blue;
  @apply mt-4;
  &:last-child {
    @apply mb-3;
  }
  &-text {
    @apply text-11;
    @apply font-normal;
  }
}
@-webkit-keyframes slide-down {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateX(0px);
  }
}
